"use client";

import { sliceAnchor } from "@/utils/prismic";
import { Heading, Paragraph } from "@cgc/ui/typography";
import { CheckIcon } from "@heroicons/react/24/solid";
import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import clsx from "clsx";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { useEffect, useRef, useState } from "react";
/**
 * Props for `GiftMembershipsSpiritChoice`.
 */
export type GiftMembershipsSpiritChoiceProps =
  SliceComponentProps<Content.GiftMembershipsSpiritChoiceSlice>;

/**
 * Component for "GiftMembershipsSpiritChoice" Slices.
 */
const GiftMembershipsSpiritChoice = ({
  slice,
}: GiftMembershipsSpiritChoiceProps): JSX.Element => {
  const sliceContent = slice.primary;
  const [spirit, setSpirit] = useState<"gin" | "rum" | null>(null);
  const [pricePoints, setPricePoints] = useState(
    slice.primary.number_of_boxes.filter((item) => item.spirit === "gin"),
  );
  const spiritChoiceRef = useRef<HTMLDivElement>(null);
  const priceChoiceRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  useEffect(() => {
    if (spirit) {
      setPricePoints(
        slice.primary.number_of_boxes.filter((item) => item.spirit === spirit),
      );
      priceChoiceRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [spirit]);

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      id={sliceAnchor(slice)}
    >
      <div
        ref={spiritChoiceRef}
        className="relative mx-auto w-full max-w-screen-lg space-y-6 p-4"
      >
        <div className="text-cgc-grey w-full text-center">
          <Heading size="h2">{slice.primary.first_step_title}</Heading>
        </div>
        <div className="flex w-full flex-col gap-4 md:flex-row">
          <SelectionCard
            spirit="gin"
            setSpirit={setSpirit}
            isSelected={spirit === "gin"}
            image={sliceContent.gin_image.url}
            heading={sliceContent.gin_heading}
            copy={sliceContent.gin_copy}
          />

          <SelectionCard
            spirit="rum"
            setSpirit={setSpirit}
            isSelected={spirit === "rum"}
            image={sliceContent.rum_image.url}
            heading={sliceContent.rum_heading}
            copy={sliceContent.rum_copy}
          />
        </div>
      </div>

      <div ref={priceChoiceRef} className="space-y-6">
        <div className="text-cgc-grey w-full text-center">
          <Heading size="h2">{slice.primary.second_step_title}</Heading>
        </div>
        <div className="relative w-full overflow-hidden p-4">
          <Image
            src={sliceContent.background_image.url}
            alt={sliceContent.background_image.url}
            width={3000}
            height={900}
            className="absolute inset-0 top-1/2 h-full w-full -translate-y-1/2 object-cover object-center"
          />

          <div className="mx-auto flex w-full max-w-screen-lg flex-col lg:flex-row-reverse lg:p-2">
            {/* Price grid */}
            <div className="grid w-full grid-cols-2 gap-6 gap-x-2 py-4 lg:p-8">
              {pricePoints.map((box) => (
                <div>
                  <div
                    className={`no-underline ${spirit ? "cursor-pointer" : "cursor-default"}`}
                    onClick={() => {
                      if (!spirit) {
                        spiritChoiceRef.current.scrollIntoView({
                          behavior: "smooth",
                        });
                      } else {
                        router.push(
                          box.link ?? "https://www.craftginclub.co.uk/gifts",
                        );
                      }
                    }}
                  >
                    <div className="relative rounded-md border-2 border-white bg-white/15 bg-none py-2">
                      {box.full_price && (
                        <div className="absolute -top-4 left-1/2 -translate-x-1/2 bg-green-100 px-8 text-gray-900 line-through md:-top-4">
                          <Paragraph className="text-cgc-grey-80 py-0.5 text-center font-bold">
                            £{box.full_price}
                          </Paragraph>
                        </div>
                      )}
                      <Paragraph className="py-2 text-center font-bold text-white">
                        £{box.price} - {box.number_of_months} Months
                      </Paragraph>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Spacer block */}
            <div className="bg-cgc-xmas-green23 h-48 w-full" />
          </div>
        </div>
      </div>
    </section>
  );
};

const SelectionCard = ({
  spirit,
  setSpirit,
  isSelected,
  image,
  heading,
  copy,
}: {
  spirit: "gin" | "rum";
  setSpirit: (spirit: "gin" | "rum") => void;
  isSelected: boolean;
  image: string;
  heading: string;
  copy: string;
}) => {
  return (
    <div
      className={
        "border-cgc-xmas-green23 relative mb-2 flex w-full flex-row gap-4 rounded-md border-2 p-4 md:mb-0 md:flex-col"
      }
      onClick={() => setSpirit(spirit)}
    >
      <div
        className={clsx(
          "border-cgc-xmas-green23 absolute -top-3 left-1/2 h-6 w-6 -translate-x-1/2 rounded-full border-2",
          {
            "bg-cgc-xmas-green23 text-white": isSelected,
            "bg-white": !isSelected,
          },
        )}
      >
        {isSelected && <CheckIcon />}
      </div>
      <div className="flex w-1/2 flex-col gap-4 md:w-full md:flex-col-reverse">
        <Paragraph size="p1" className="w-full text-wrap font-bold">
          {heading}
        </Paragraph>

        <Image
          className="aspect-[3/2] w-full object-cover"
          src={image}
          alt={heading}
          width={400}
          height={400}
        />
      </div>
      <div className="w-1/2 md:w-full">
        <Paragraph size="p1" className="w-full text-wrap">
          {copy}
        </Paragraph>
      </div>
    </div>
  );
};

export default GiftMembershipsSpiritChoice;
